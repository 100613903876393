import React from "react"
import pets from "../images/pets.png"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./index.css"

const IndexPage = () => (
  <Layout subTitle="home">
    <SEO title="Home" />
      <div className="enclosingRow">
        <div className="textContent">
          <div className="textItem" align="justify">
            This site exists to test ideas and stay current with software
            and technology that I don't have the opportunity to work
            with professionally. 
            It is a continual work in progress and may take a step back at
            times if I'm experimenting with something new.
          </div>
          <div className="textItem" align="justify">
            Everything here was created using
            ReactJS, CSS and HTML. No <a 
              href="https://en.wikipedia.org/wiki/List_of_content_management_systems" 
              target="_blank" 
              rel="noopener noreferrer"
            >CMS</a> is used.
            Building the site is "manual" and that's the point. Please
            don't judge my design skills/sensibilities based on the look 
            and feel. ;-)
          </div>
          <div className="textItem" align="justify">
            Gatsby was used to make S3 deployments
            easy and the site cheap (pennies/mo) to host.
            A future project is to implement this site using {" "}
            <a href="https://aws.amazon.com/amplify/" 
              target="_blank" 
              rel="noopener noreferrer"
            >AWS Amplify</a>{" "}
            to compare features, complexity and cost. 
          </div>
        </div>
        <div className="imageContent">
            <img style={{
              borderRadius: "20%",
              boxShadow: "5px 5px 30px 7px rgba(0,0,0,0.25), -5px -5px 30px 7px rgba(0,0,0,0.22)",
            }} src={pets} alt="pets" />
          <em>"more cheese please"</em>
        </div>
        <code className="textItem" align="justify" style={{margin: ".6em", fontSize: "75%"}}>
            “The fact that we live at the bottom of a deep gravity well, on the
            surface of a gas covered planet going around a nuclear fireball 90
            million miles away and think this to be normal is obviously some
            indication of how skewed our perspective tends to be.” <br />― <b>Douglas Adams</b>
          </code>
      </div>
  </Layout>
)

export default IndexPage
